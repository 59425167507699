<template>
  <div
    style="height: 100%"
    ref="amenities"
    :class="{ right_content: language == 'ar' }"
  >
    <van-sticky>
      <van-nav-bar
        class="header"
        :title="stringList[meetingService]"
        @click-left="onClickLeft"
      >
        <template #left>
          <img
            src="../assets/img/back_new.png"
            style="width: 20px; height: 20px"
          />
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="container">
      <div class="form-title">
        <span>{{ stringList.new_request }}</span>
      </div>
      <div class="form">
        <div class="form-item required">
          <a-select
            show-search
            size="large"
            class="roomNum"
            ref="select1"
            :placeholder="stringList.enter_room"
            :getPopupContainer="() => $refs.amenities"
            :default-active-first-option="false"
            :filter-option="false"
            :not-found-content="null"
            @search="roomSearch"
            @change="roomChange"
            :value="roomNumber"
            @focus="clearRoomNumber"
            :autofocus="false"
            dropdownClassName="roomNumber"
            :notFoundContent="notFoundContent"
          >
            <template #suffixIcon>
              <img
                style="width: 15px"
                src="@/assets/img/hotel-please-clean.png"
              />
            </template>
            <a-select-option
              v-for="item in roomArray"
              :key="item.id"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="form-item required" v-if="meetingService">
          <van-field
            v-model="meetingRequest"
            style="height: auto"
            type="textarea"
            maxlength="50"
            rows="3"
            :placeholder="stringList.enter_your_request"
            class="request-form"
          />
        </div>

        <!-- 日期控件 -->
        <van-row>
          <van-col span="11">
            <div class="form-item required">
              <van-field
                readonly
                :placeholder="stringList.today"
                :value="date"
                @click="deteSelect"
                :class="{ placeholderColor: date == '' }"
              >
                <template #right-icon>
                  <img
                    src="@/assets/img/calendar-line.png"
                    style="height: 15px"
                  />
                </template>
              </van-field>
            </div>
          </van-col>
          <van-col span="2"><div></div></van-col>
          <van-col span="11">
            <div class="form-item required">
              <van-field
                readonly
                :value="time"
                @click="selectTime"
                :placeholder="stringList.asap"
                :class="{ placeholderColor: time == '' }"
              >
                <template #right-icon>
                  <img src="@/assets/img/clock-time.png" style="height: 15px" />
                </template>
              </van-field>
            </div>
          </van-col>
        </van-row>

        <div class="form-item">
          <van-field
            v-model="notes"
            style="height: auto"
            type="textarea"
            rows="3"
            maxlength="200"
            :placeholder="stringList.comments_placeholder"
            class="comment"
          />
        </div>

        <div class="form-item" v-if="showPhone">
          <span
            class="text"
            style="font-size: 15px"
            :class="{ right_text_item: language == 'ar' }"
          >
            <span
              :class="{ right_text_item: language == 'ar' }"
              style="display: block"
              >{{ stringList.optional1 }}&nbsp;&nbsp;{{
                stringList.optional2
              }}</span
            >
          </span>
        </div>
        <div class="form-item" v-if="showPhone">
          <van-field
            v-model="phone"
            type="tel"
            :placeholder="stringList.mobile_number"
            maxlength="12"
            @input="validateMobile"
          ></van-field>
        </div>
      </div>
    </div>
    <div class="bottom">
      <!-- <div class="btn" @click="createOrder" :style="{ color: modifiedColor }">
        <span>{{ stringList.submit }}</span>
      </div> -->
      <van-button @click="createOrder" class="btn">{{
        stringList.submit
      }}</van-button>
    </div>
    <!--  日历控件  -->
    <van-calendar
      v-model="showDate"
      :color="primaryColor"
      :show-title="false"
      :min-date="getMinDate()"
      @confirm="dateConfirm"
      :default-date="getDefaultDate()"
    />

    <!-- 时间选择 -->
    <van-popup v-model="showTime" position="bottom">
      <van-picker
        :columns="timeColumns"
        show-toolbar
        @confirm="timeConfirm"
        @cancel="showTime = false"
      />
    </van-popup>

    <!-- 特殊流程弹窗 -->
    <van-popup
      v-model="confirmationPop"
      round
      position="bottom"
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div class="pop-title" style="margin-bottom: 20px">
          <span class="title">{{ stringList.confirmation }}</span>
        </div>
        <div class="pop-info" style="padding: 0 40px">
          <div
            v-if="
              selectRequest.section != 'ScheduleAfterHour' &&
              selectRequest.section != 'ScheduleNextDay'
            "
            class="text"
            style="font-size: 14px"
            :class="{ right_text_item: language == 'ar' }"
          >
            {{ confirmation1 }}
          </div>
          <div
            class="text"
            style="margin-bottom: 20px; font-size: 14px"
            :class="{ right_text_item: language == 'ar' }"
          >
            {{ confirmation }}
          </div>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <van-radio-group
              v-model="fdAction"
              icon-size="14"
              direction="horizontal"
              style="
                justify-content: space-between;
                width: 150px;
                margin-bottom: 10px;
              "
            >
              <van-radio shape="square" name="Yes">
                <span>{{ stringList.yes }}</span></van-radio
              >
              <van-radio shape="square" name="No">
                <span>{{ stringList.no }}</span></van-radio
              >
            </van-radio-group>
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="confirmationPopOK">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="confirmationPopOK" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>

    <!-- TransferToFD或者TransferToRS yes分支弹窗 -->
    <van-popup
      v-model="thankPop2"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span class="title">{{ stringList.thank }}</span>
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            <!-- {{ okContent }} -->
            {{ stringList.responseContent4 }}
            <span v-if="language == 'en'">{{ selectRequest.keyword }}.</span>
            <span v-else>{{ selectRequest[language] }}.</span>
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="() => $router.go(-1)">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="() => $router.go(-1)" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>
    <!-- Answer分支弹窗 -->
    <van-popup
      v-model="thankPop3"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span class="title">{{ stringList.thank }}</span>
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.your_request }}
          </div>
          <div
            class="info-item"
            style="margin-bottom: 10px; font-weight: 600"
            :class="{ right_keywords_item: language == 'ar' }"
          >
            <span v-if="language == 'en'">{{ selectRequest.keyword }}</span>
            <span v-else>{{ selectRequest[language] }}</span>
          </div>
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ okContent }}
          </div>
          <template v-for="(file, index) in fileList">
            <div
              :key="index"
              v-if="showPdf(selectRequest.keyword, file)"
              @click="pdfView(file.fileUrl, file.title)"
              class="text"
              style="text-decoration: underline"
              :class="{ right_text_item: language == 'ar' }"
            >
              {{ JSON.parse(file.title2)[language] }}
            </div>
          </template>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <!-- <div class="btn" @click="() => $router.go(-1)">
            <span>{{ stringList.ok }}</span>
          </div> -->
          <van-button @click="() => $router.go(-1)" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>
    <!-- submit弹窗  meeting-->
    <van-popup
      v-model="thankPop"
      style="width: 300px"
      round
      :close-on-click-overlay="false"
      :get-container="() => $refs.amenities"
    >
      <div class="pop">
        <div
          class="pop-title3"
          style="margin-bottom: 20px"
          :class="{ right_keywords_title: language == 'ar' }"
        >
          <span class="title">{{ stringList.thank }}</span>
        </div>
        <div class="pop-info">
          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{ stringList.responseContent1 }}
          </div>
          <div>
            <div
              class="info-item"
              style="font-weight: 600"
              :class="{ right_keywords_item: language == 'ar' }"
            >
              <span>{{ meetingServiceTitle }}</span>
            </div>
          </div>
          <div
            class="text"
            :class="{ right_text_item: language == 'ar' }"
            style="padding-left: 20px"
          >
            {{ tips }}
          </div>

          <div class="text" :class="{ right_text_item: language == 'ar' }">
            {{
              pageType == "nonguest" ? okContent : stringList.responseContent2
            }}
          </div>
        </div>
        <div class="btn-area" style="border-top: 0px">
          <van-button @click="() => $router.go(-1)" class="btn">{{
            stringList.ok
          }}</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import moment from "moment-timezone";
import { postAction, getAction } from "@/api/manage";
import { Toast } from "vant";
import { encryptedDES } from "@/utils/DES.js";

export default {
  metaInfo: {
    meta: [
      {
        name: "viewport",
        content:
          "width=device-width, initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no",
      },
    ],
  },
  name: "MeetingService",
  data() {
    return {
      requestList: [],
      otherRequestList: [],
      overNightHours: Vue.ls.get("overNightHours"),
      url: {
        isservicehour: "/api/app/current_user/isservicehour/",
        createOrder: "/api/common/hotel/data/qrorder/create",
        login: "/api/common/user/hotel_dept_name/login",
        hotelQuery: "/api/sys_admin/hotel/query",
        hotelinfo: "/adminApi/hotelinfo/infobyhotel",
        getPageInfo: "/adminApi/file/page",
        queryRoomNum: "/api/sys_admin/hotel/room/query2/",
        getKeyWord: "/api/app/current_user/keywordsalldept/",
        getReplyList: "/adminApi/reply/listbyhotel",
        getIsInputPhone: "/adminApi/textconfig/list",
        getFileList: "/adminApi/file/filelist",
        getLanguageList: "/api/common/hotel/data/dict/by_key/languageQr",
        about: "/adminApi/test/qr/about1",
      },

      roomNumber: "",
      notes: "",
      date: "",
      showDate: false,
      time: "",
      showTime: false,
      roomArray: [],
      phone: "",
      timeColumns: [],
      selectRequest: {},

      requestPop: true,
      searchRequest: undefined,

      //特殊流程
      confirmationPop: false,
      fdAction: "",
      confirmation: "",
      tips: "",
      thankPop: false,
      okContent: "",
      //防止多次提交
      submiting: false,
      thankPop2: false, //TransferToFD或者TransferToRS yes分支弹窗
      thankPop3: false, //Answer分支弹窗
      timeDisabled: true, //时间不可选

      notFoundContent: "",
      fileList: Vue.ls.get("fileList"),
      language: Vue.ls.get("language"),
      stringList: {},
      confirmation1: "",
      meetingService: "",
      meetingRequest: "",
      meetingServiceType: "",
      meetingServiceTitle: "",
      pageType: Vue.ls.get("type"),
      serviceTitle: {
        "AV Assistance": "AV_Assistance",
        "Beverage/Coffee Refill": "Beverage",
        "Breaktime/Mealtime Change": "Breaktime",
        "Request Hotel Staff": "Request_Hotel_Staff",
        "Temperature Control": "Temperature_Control",
        "Other/Miscellaneous": "Miscellaneous",
      },
      color: "",
    };
  },
  watch: {
    allRequest() {
      if (this.requestList.length == 0) {
        this.requestList = this.allRequest.filter((item) => {
          if (item.serviceType == "Service") {
            return item;
          }
        });
      }
    },
    meetingRequest(n, o) {
      if (n.length >= 50) {
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;width="300px"><span>` +
            this.stringList.limit_request_description +
            `</span></div>`,
          type: "html",
        });
        return;
      }
    },
    notes(n, o) {
      if (n.length >= 200 && n.length != 0 && this.language == "ar") {
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;width=300px;direction:rtl"><span>` +
            this.stringList.limit_description +
            `</span></div>`,
          type: "html",
        });
      } else if (n.length >= 200 && n.length != 0) {
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;width=300px"><span>` +
            this.stringList.limit_description +
            `</span></div>`,
          type: "html",
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      allRequest: "informationRequest", //所有request
      roomList: "roomList",
      showPhone: "showPhone",
      primaryColor: "primaryColor",
      amcniticsRequest: "amcniticsRequest",
      informationRequest: "informationRequest",
    }),
    modifiedColor() {
      // 计算新的颜色
      if (this.isEventTriggered) {
        const originalColor = this.primaryColor;
        // 解析颜色值，假设颜色值是16进制形式
        const hexColor = originalColor.replace("#", "");
        const r = parseInt(hexColor.slice(0, 2), 16);
        const g = parseInt(hexColor.slice(2, 4), 16);
        const b = parseInt(hexColor.slice(4, 6), 16);

        // 加重30%
        const newR = Math.min(255, r * 1.3);
        const newG = Math.min(255, g * 1.3);
        const newB = Math.min(255, b * 1.3);

        // 转换回16进制颜色
        const newHexColor = `#${Math.round(newR)
          .toString(16)
          .padStart(2, "0")}${Math.round(newG)
          .toString(16)
          .padStart(2, "0")}${Math.round(newB).toString(16).padStart(2, "0")}`;
        return newHexColor;
      } else {
        return this.primaryColor; // 未触发事件时返回原始颜色
      }
    },
  },
  async created() {
    //无痕浏览器直接进入逻辑
    let tsp = this.$route.query.tsp;
    let now = new Date().getTime();
    console.log(now - tsp * 1000);
    this.roomNumber = Vue.ls.get("room") ? Vue.ls.get("room") : undefined;
    if (now - this.tsp * 1000 > 1000 * 60 * 60 * 24) {
      //如果是走了这个about 后台调用一下接口 打log
      getAction(this.url.about).then((res) => {});
      this.$router.push({ name: "About" });
    } else {
      if (Vue.ls.get("meeting_service")) {
        // this.meetingService = this.$route.query.service
        this.meetingServiceType = Vue.ls.get("meeting_service");
        this.meetingService = this.serviceTitle[this.meetingServiceType];
        getAction(
          `${this.url.queryRoomNum}${"Meeting Room"}/${"allmeeting"}`
        ).then((res) => {
          if (res.status == 0) {
            this.editRoomList(res.data);
          }
        });
      }
      if (!Vue.ls.get("HTTOKEN")) {
        let params = {
          hotelDeptName: "qrbot",
          hotelName: this.$route.query.inno,
          password: "123456",
        };
        postAction(this.url.login, params).then((res) => {
          if (res.status == 0) {
            this.token = res.data.token;
            Vue.ls.set("HTTOKEN", res.data.token);
            getAction(this.url.getLanguageList).then((res) => {
              Vue.ls.set("languageList", res.data);
            });
            postAction(this.url.hotelQuery, {
              hotelNo: this.$route.query.inno,
            }).then((res) => {
              if (res.status == 0) {
                Vue.ls.set("timeZone", res.data.timeZone, 1000 * 60 * 60 * 24);
                Vue.ls.set("hotelId", res.data.id, 1000 * 60 * 60 * 24);
                //判断是否启动夜间模式
                Vue.ls.set(
                  "overNightHoursInfo",
                  res.data.overNightHours,
                  1000 * 60 * 60 * 24
                );
                // if (
                //   res.data.overNightHours != "disable" &&
                //   res.data.overNightHours != "Regular Operating Hour"
                // ) {
                //   Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                // } else {
                //   Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                // }
                if (
                  res.data.overNightHours == "disable" ||
                  res.data.overNightHours == "Regular Operating Hour"
                ) {
                  Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                } else if (
                  res.data.overNightHours == "Cycle Regular Operating Hour"
                ) {
                  // Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                  let now = new Date();
                  let taody = moment(now)
                    .tz(res.data.timeZone)
                    .format("YYYY/MM/DD");
                  let startTime = moment.tz(
                    moment(taody + " " + res.data.fdStartTime).format(
                      "YYYY-MM-DD HH:mm"
                    ),
                    res.data.timeZone
                  );
                  let endTime = moment.tz(
                    moment(
                      res.data.fdEndTime.replace("tomorrow_", taody + " ")
                    ).format("YYYY-MM-DD HH:mm"),
                    res.data.timeZone
                  );
                  if (this.getIsInTimeRange(startTime, endTime)) {
                    Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                  } else {
                    Vue.ls.set("overNightHours", false, 1000 * 60 * 60 * 24);
                  }
                } else {
                  Vue.ls.set("overNightHours", true, 1000 * 60 * 60 * 24);
                }
                Vue.ls.set(
                  "fdStartTime",
                  res.data.fdStartTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "fdEndTime",
                  res.data.fdEndTime,
                  1000 * 60 * 60 * 24
                );

                Vue.ls.set("engHour", res.data.engHour, 1000 * 60 * 60 * 24);
                Vue.ls.set(
                  "engEndTime",
                  res.data.engEndTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "engStartTime",
                  res.data.engStartTime,
                  1000 * 60 * 60 * 24
                );
                Vue.ls.set(
                  "engRequestTime",
                  res.data.engRequestTime,
                  1000 * 60 * 60 * 24
                );
                getAction(this.url.hotelinfo, { hotelId: res.data.id }).then(
                  (r) => {
                    if (r.status == 0) {
                      this.editColor("#" + r.data.color);
                    }
                  }
                );
                postAction(this.url.getFileList, { hotelId: res.data.id }).then(
                  (r) => {
                    if (r.status == 0) {
                      Vue.ls.set(
                        "fileList",
                        r.data.htFileList,
                        1000 * 60 * 60 * 24
                      );
                    }
                  }
                );
                let params = {
                  hotelId: Vue.ls.get("hotelId"),
                  type: "internal",
                };
                getAction(this.url.getReplyList, params).then((res) => {
                  if (res.status == 0) {
                    for (let element of res.data) {
                      if (
                        element.botTrigger == "556" ||
                        element.botTrigger == "557"
                      ) {
                        Vue.ls.set(
                          "fdOption",
                          element.botTriggerValue,
                          1000 * 60 * 60 * 24
                        );
                        Vue.ls.set(
                          "fdOptionReponse",
                          element.botResponse2,
                          1000 * 60 * 60 * 24
                        );
                        break;
                      }
                    }
                    for (let element of res.data) {
                      if (element.botTrigger == "677") {
                        Vue.ls.set(
                          "engHourReponse",
                          element.botResponse2,
                          1000 * 60 * 60 * 24
                        );
                        break;
                      }
                    }
                  }
                });
                let form = {
                  hotelId: Vue.ls.get("hotelId"),
                  size: 10,
                  current: 1,
                };
                postAction(this.url.getIsInputPhone, form).then((res) => {
                  if (res.status == 0) {
                    res.data.records.forEach((item) => {
                      if (Vue.ls.get("type") == "guest") {
                        if (item.requestType == "Guest Room Request") {
                          if (item.textResponse == "Enable") {
                            this.editShowPhone(true);
                            return;
                          }
                        }
                      } else if (Vue.ls.get("type") == "nonguest") {
                        if (item.requestType == "Meeting Room Request") {
                          if (item.textResponse == "Enable") {
                            this.editShowPhone(true);
                            return;
                          }
                        }
                      }
                    });
                  }
                });
              }
            });
            if (this.amcniticsRequest.length == 0) {
              getAction(`${this.url.getKeyWord}${"fdkey"}`).then((res) => {
                if (res.status == 0) {
                  this.editAmcniticsRequest(res.data);
                }
              });
            }
            if (this.informationRequest.length == 0) {
              getAction(`${this.url.getKeyWord}${"nonfdkey"}`).then((res) => {
                if (res.status == 0) {
                  this.editInformationRequest(res.data);
                }
              });
            }
            debugger;
            if (this.$route.query.type) {
              Vue.ls.set("type", this.$route.query.type, 1000 * 60 * 60 * 24);
              if (Vue.ls.get("type") == "guest") {
                getAction(
                  `${this.url.queryRoomNum}${"guest"}/${"allmeeting"}`
                ).then((res) => {
                  if (res.status == 0) {
                    this.editRoomList(res.data);
                  }
                });
              } else if (Vue.ls.get("type") == "nonguest") {
                getAction(
                  `${this.url.queryRoomNum}${"Meeting Room"}/${"allmeeting"}`
                ).then((res) => {
                  if (res.status == 0) {
                    this.editRoomList(res.data);
                  }
                });
              }
            }
            if (this.$route.query.room) {
              Vue.ls.set("room", this.$route.query.room, 1000 * 60 * 60 * 24);
            }
            if (this.$route.query.inno) {
              Vue.ls.set("inno", this.$route.query.inno, 1000 * 60 * 60 * 24);
            }
          }
        });
        if (!Vue.ls.get("language")) {
          Vue.ls.set("language", "en");
          this.language = "en";
        }
        this.requestList = this.allRequest.filter((item) => {
          if (item.serviceType == "Service") {
            // if (item.section == "Answer") {
            //   console.log(item);
            // }
            return item;
          }
        });
        // 初始化时间控件
        let hour = [];
        for (let i = 1; i <= 12; i++) {
          if (i < 10) {
            hour.push("0" + i);
          } else {
            hour.push("" + i);
          }
        }
        this.timeColumns.push({
          values: hour,
        });
        let minte = [];
        for (let i = 0; i <= 59; i++) {
          if (i < 10) {
            minte.push("0" + i);
          } else {
            minte.push("" + i);
          }
        }
        this.timeColumns.push({
          values: minte,
        });
        this.timeColumns.push({
          values: ["AM", "PM"],
        });
        let languageJsonName = "String_" + Vue.ls.get("language") + ".json";
        this.stringList = await import("@/data/" + languageJsonName);
        this.meetingServiceTitle = this.stringList[this.meetingService];
      } else {
        this.requestList = this.allRequest.filter((item) => {
          if (item.serviceType == "Service") {
            // if (item.section == "Answer") {
            //   console.log(item);
            // }
            return item;
          }
        });
        // 初始化时间控件
        let hour = [];
        for (let i = 1; i <= 12; i++) {
          if (i < 10) {
            hour.push("0" + i);
          } else {
            hour.push("" + i);
          }
        }
        this.timeColumns.push({
          values: hour,
        });
        let minte = [];
        for (let i = 0; i <= 59; i++) {
          if (i < 10) {
            minte.push("0" + i);
          } else {
            minte.push("" + i);
          }
        }
        this.timeColumns.push({
          values: minte,
        });
        this.timeColumns.push({
          values: ["AM", "PM"],
        });
        let languageJsonName = "String_" + Vue.ls.get("language") + ".json";
        this.stringList = await import("@/data/" + languageJsonName);
        console.log(this.stringList);

        this.meetingServiceTitle = this.stringList[this.meetingService];
      }
    }
  },
  methods: {
    moment,
    ...mapActions([
      "editColor",
      "editAmcniticsRequest",
      "editInformationRequest",
      "editShowPhone",
      "editRoomList",
    ]),
    pdfView(inno, title) {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      let urlType = inno.split(".")[inno.split(".").length - 1];
      //创建订单
      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));
      let time = new Date().valueOf().toString();
      let params = {
        createTime: createTime.utc().format(),
        fdaction: "",
        fdhour: Vue.ls.get("overNightHoursInfo"),
        hotelId: Vue.ls.get("hotelId"),
        hotelDeptId: "",
        inquiryNum: "",
        notes: "",
        requestType: Vue.ls.get("type"),
        roomNumber: Vue.ls.get("room") ? Vue.ls.get("room") : "GSD",
        requestTime: createTime.utc().format(),
        serviceKey: title ? title : "Guest Service Directory",
        serviceType: "gsd",
        createTimeValue: time,
        tid: encryptedDES(time.substring(time.length - 8)),
        language: this.language,
      };

      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.createOrder, params)
          .then((res) => {
            if (res.success) {
              this.submiting = false;
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }

      if (
        urlType == "xlsx" ||
        urlType == "docx" ||
        urlType == "pptx" ||
        urlType == "xls" ||
        urlType == "doc" ||
        urlType == "ppt"
      ) {
        window.location.href =
          "https://view.officeapps.live.com/op/view.aspx?src=" + inno;
      } else if (
        urlType == "jpg" ||
        urlType == "png" ||
        urlType == "jpeg" ||
        urlType == "gif"
      ) {
        window.location.href = inno;
      } else if (urlType == "pdf") {
        this.$router.push({
          name: "PdfView",
          query: { inno: inno },
        });
      } else {
        window.location.href = inno;
      }
    },
    showPdf(keyword, file) {
      if (!keyword) return false;
      let services = file.service;
      for (let ss of services.split(",")) {
        if (
          keyword.toLowerCase() == ss.toLowerCase() &&
          file.enable != null &&
          file.enable
        ) {
          return true;
        }
      }
      return false;
    },
    claerSearchRequest() {
      this.searchRequest = undefined;
      this.otherRequestList = this.requestList;
    },
    clearRoomNumber() {
      console.log("执行");
      if (this.roomNumber == undefined || this.roomNumber == "") {
        console.log("undefined");
        this.$nextTick(() => {
          this.$refs.select1.blur();
        });
        this.roomArray = this.roomList;
      } else {
        console.log("清除房间号");
        this.roomNumber = "";
        this.roomArray = [];
        this.notFoundContent = "";
        this.$nextTick(() => {
          this.$refs.select1.focus();
        });
        this.roomArray = this.roomList;
      }
    },
    getIsInTimeRange(startTime, endTime) {
      let now = new Date();
      if (startTime.valueOf() < endTime.valueOf()) {
        return (
          startTime.valueOf() <= now.getTime() &&
          now.getTime() <= endTime.valueOf()
        );
      } else {
        return (
          startTime.valueOf() <= now.getTime() ||
          now.getTime() <= endTime.valueOf()
        );
      }
    },
    onClickLeft() {
      // this.$router.go(-1);
      let query = {};
      query.type = this.$route.query.type;
      query.inno = this.$route.query.inno;
      if (this.$route.query.room) {
        query.room = this.$route.query.room;
      }
      query.tsp = this.$route.query.tsp;
      this.$router.replace({ name: "Home", query: query });
    },
    validateMobile(val) {
      //美式mobile 号码格式，3-3-4格式
      //代码参照https://github.com/wuhao000/antd-mobile-vue/blob/master/src/packages/input/src/index.tsx
      let ctrlValue = val;
      ctrlValue = val.replace(/\D/g, "").substring(0, 11);
      const valueLen = ctrlValue.length;
      if (valueLen > 3 && valueLen < 7) {
        ctrlValue = `${ctrlValue.substr(0, 3)}-${ctrlValue.substr(3)}`;
      } else if (valueLen >= 7) {
        ctrlValue = `${ctrlValue.substr(0, 3)}-${ctrlValue.substr(
          3,
          3
        )}-${ctrlValue.substr(6)}`;
      }
      this.phone = ctrlValue;
      // this.$set(this.requestForm, "phone", ctrlValue);
    },

    roomSearch(value) {
      if (value) {
        this.roomArray = this.roomList.filter((item) => {
          return item.name.indexOf(value) != -1;
        });
        this.notFoundContent = this.stringList.invalid_room;
      } else {
        this.roomArray = [];
        this.notFoundContent = "";
      }
    },
    roomChange(value) {
      this.$nextTick(() => {
        this.$refs.select1.blur();
      });
      this.roomNumber = value;
      this.roomArray = this.roomList.filter((item) => {
        return item.name.indexOf(value) != -1;
      });
    },
    handleSearch(value) {
      if (this.language == "en") {
        this.otherRequestList = this.requestList.filter((item) => {
          return item.keyword.toLowerCase().indexOf(value.toLowerCase()) != -1;
        });
      } else {
        this.otherRequestList = this.requestList.filter((item) => {
          if (item[this.language]) {
            return (
              item[this.language].toLowerCase().indexOf(value.toLowerCase()) !=
              -1
            );
          }
        });
      }
    },
    handleChange(value) {
      this.searchRequest = value;
      this.otherRequestList = this.requestList.filter((item) => {
        return item.keyword.toLowerCase().indexOf(value.toLowerCase()) != -1;
      });
    },

    dateConfirm(date) {
      // console.log(moment(date.getTime()).format("YYYY/MM/DD"));
      this.date = moment(date.getTime()).format("MM/DD/YYYY");
      // 判断是否为今天
      if (
        this.date !=
        moment(new Date().getTime())
          .tz(Vue.ls.get("timeZone"))
          .format("MM/DD/YYYY")
      ) {
        this.time = "10:00 AM";
      } else {
        this.time = this.stringList.asap;
      }
      this.showDate = false;
    },
    getDefaultDate() {
      let date = moment(new Date())
        .tz(Vue.ls.get("timeZone"))
        .format("YYYY/MM/DD");
      // console.log(date);
      return new Date(date);
    },
    getMinDate() {
      let date = moment(new Date())
        .tz(Vue.ls.get("timeZone"))
        .format("YYYY/MM/DD");
      // console.log(date);
      return new Date(date);
    },
    timeConfirm(value) {
      this.showTime = false;
      this.timePickerData = value;
      this.time = value[0] + ":" + value[1] + " " + value[2];
    },
    selectTime() {
      if (this.timeDisabled) {
        this.showTime = true;
        let time = moment(new Date())
          .tz(Vue.ls.get("timeZone"))
          .format("hh:mm A");
        this.timeColumns[0].defaultIndex = Number(time.slice(0, 2)) - 1;
        this.timeColumns[1].defaultIndex = Number(time.slice(3, 5));
        this.timeColumns[2].defaultIndex =
          time.slice(time.length - 2, time.length) == "AM" ? 0 : 1;
      }
    },
    deteSelect() {
      if (this.timeDisabled) {
        this.showDate = true;
      }
    },

    showRequestPop() {
      this.requestPop = true;
      if (this.selectRequest.keyword != this.searchRequest) {
        this.searchRequest = undefined;
        this.otherRequestList = this.requestList;
      }
    },

    addRequest(request) {
      this.selectRequest = request;
      this.confirmation1 = JSON.parse(request.response)[this.language];
      console.log(this.confirmation1);
      // if (this.isJSON(request.response)) {
      //   this.selectRequest.response = JSON.parse(request.response)[
      //     this.language
      //   ];
      // }
      this.requestPop = false;
      this.fdAction = "";
      this.isUseConfirmationPop(request);
    },
    // isJSON(str) {
    //   if (typeof str == "string") {
    //     try {
    //       var obj = JSON.parse(str);
    //       if (typeof obj == "object" && obj) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     } catch (e) {
    //       console.log("error：" + str + "!!!" + e);
    //       return false;
    //     }
    //   }
    // },
    addOtherRequest() {
      if (this.searchRequest) {
        for (let i = 0; i < this.requestList.length; i++) {
          const item = this.requestList[i];
          if (item.keyword == this.searchRequest) {
            this.selectRequest = item;
          }
        }
        this.requestPop = false;
        this.fdAction = "";
        this.isUseConfirmationPop(this.selectRequest);
        // console.log(this.selectRequest)
      }
    },

    //特殊弹窗逻辑
    isUseConfirmationPop(request) {
      console.log(request);

      //ScheduleAfterHour或者ScheduleNextDay 的requeset时间固定不可修改
      let now = new Date();
      let taody = moment(now).tz(Vue.ls.get("timeZone")).format("YYYY/MM/DD");
      let tomorrow = moment(now.getTime() + 1000 * 60 * 60 * 24)
        .tz(Vue.ls.get("timeZone"))
        .format("YYYY/MM/DD");
      this.date = "";
      this.time = "";
      this.timeDisabled = true;
      if (
        this.selectRequest.section == "ScheduleNextDay"
      ) {
        this.timeDisabled = false;
        let time = "";
        if (this.selectRequest.cnfgRequestTime.indexOf("Tomorrow_") != -1) {
          time = moment.tz(
            moment(
              this.selectRequest.cnfgRequestTime.replace(
                "Tomorrow_",
                tomorrow + " "
              )
            ).format("YYYY-MM-DD HH:mm"),
            Vue.ls.get("timeZone")
          );
        } else {
          time = moment.tz(
            moment(taody + " " + this.selectRequest.cnfgRequestTime).format(
              "YYYY-MM-DD HH:mm"
            ),
            Vue.ls.get("timeZone")
          );
        }
        this.date = time.format("MM/DD/YYYY");
        this.time = time.format("hh:mm A");
      }

      if (request.flow == "15") {
        // this.createOrder();
      } else if (request.section == "ScheduleAfterHour") {
        getAction(this.url.isservicehour + request.keyword).then((res) => {
          // res.data
          // eslint-disable-next-line no-constant-condition
          if (res.data == true) {
            this.fdAction = "Yes";
            this.confirmationPop = true;
            this.confirmation = JSON.parse(request.response)[this.language];

            this.timeDisabled = false;
        let time = "";
        if (this.selectRequest.cnfgRequestTime.indexOf("Tomorrow_") != -1) {
          time = moment.tz(
            moment(
              this.selectRequest.cnfgRequestTime.replace(
                "Tomorrow_",
                tomorrow + " "
              )
            ).format("YYYY-MM-DD HH:mm"),
            Vue.ls.get("timeZone")
          );
        } else {
          time = moment.tz(
            moment(taody + " " + this.selectRequest.cnfgRequestTime).format(
              "YYYY-MM-DD HH:mm"
            ),
            Vue.ls.get("timeZone")
          );
        }
        this.date = time.format("MM/DD/YYYY");
        this.time = time.format("hh:mm A");
            // this.confirmation =
            //   "We currently only schedule next day " +
            //   request.keyword +
            //   ". Would you like me to put you down on our list for tomorrow?";
          } else {
            // this.createOrder();
          }
        });
      } else if (request.section == "ScheduleNextDay") {
        this.fdAction = "Yes";
        this.confirmationPop = true;
        this.confirmation = JSON.parse(request.response)[this.language];
        // this.confirmation =
        //   "We currently only schedule next day " +
        //   request.keyword +
        //   ". Would you like me to put you down on our list for tomorrow?";
      } else if (
        request.section == "TransferToFD" ||
        request.section == "TransferToRS"
      ) {
        this.fdAction = "No";
        this.confirmationPop = true;
        this.confirmation = this.stringList.confirmationContent;
      } else if (request.section == "CustomerDelivery") {
        this.fdAction = "No";
        this.confirmationPop = true;
        this.confirmation = this.stringList.confirmationContent2;
      } else if (request.section == "Answer") {
        this.createOrder();
      }
    },

    confirmationPopOK() {
      if (this.fdAction == "No") {
        this.createOrder();
      } else {
        this.confirmationPop = false;
      }
    },

    createOrder() {
      if (this.fdAction == "Yes" || this.fdAction == "") {
        if (!this.roomNumber && this.selectRequest.section != "Answer") {
          Toast(this.stringList.room_toast);
          return;
        }
      }

      if (!this.meetingRequest) {
        Toast(this.stringList.service_toast);
        return;
      }

      if (this.meetingRequest.length > 50) {
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;width="300px"><span>` +
            this.stringList.limit_request_description +
            `</span></div>`,
          type: "html",
        });
        return;
      }
      if (this.notes.length > 200 && this.notes.length != 0) {
        Toast({
          message:
            `<div style="word-break: normal;font-size:14px;width=300px"><span>` +
            this.stringList.limit_description +
            `</span></div>`,
          type: "html",
        });
        return;
      }

      let now = new Date();
      let createTime = moment(now).tz(Vue.ls.get("timeZone"));
      let today = moment(now).tz(Vue.ls.get("timeZone")).format("MM/DD/YY");
      let tomorrow = moment(now.getTime() + 1000 * 60 * 60 * 24)
        .tz(Vue.ls.get("timeZone"))
        .format("MM/DD/YY");

      //确定requestTime
      let date = this.date;
      if (!date) {
        date = moment(new Date())
          .tz(Vue.ls.get("timeZone"))
          .format("YYYY/MM/DD");
      }
      let time = this.time;
      if (!time || time == this.stringList.asap) {
        time = moment(new Date()).tz(Vue.ls.get("timeZone")).format("hh:mm A");
      }
      let requestTime = moment.tz(
        moment(date + " " + time).format("YYYY-MM-DD HH:mm"),
        Vue.ls.get("timeZone")
      );

      if (requestTime.valueOf() + 180000 < now.getTime()) {
        Toast(this.stringList.time_toast);
        return;
      }
      if (this.phone != null && this.phone != "" && this.phone.length != 12) {
        Toast(this.stringList.phone_toast);
        return;
      }

      // if (!this.date && !this.time) {
      //   this.tips = this.stringList.tipsContent1;
      // } else {
      //   if (Vue.ls.get("type") == "nonguest") {
      //     this.tips = this.meetingRequest;
      //   } else {
      //     if (requestTime.format("MM/DD/YY") == today) {
      //       this.tips =
      //         this.stringList.tipsContent2 +
      //         requestTime.format("hh:mm A") +
      //         ".";
      //     } else if (requestTime.format("MM/DD/YY") == tomorrow) {
      //       this.tips =
      //         this.stringList.tipsContent3 +
      //         requestTime.format("hh:mm A") +
      //         ".";
      //     } else {
      //       this.tips =
      //         this.stringList.tipsContent4 +
      //         requestTime.format("MM/DD/YY hh:mm A") +
      //         ".";
      //     }
      //   }
      // }
      this.tips = this.meetingRequest;

          if (
        (this.selectRequest.section == "ScheduleAfterHour"&&this.fdAction == "Yes")||
        this.selectRequest.section == "ScheduleNextDay"
      ) {
        let time = moment(new Date()).tz(Vue.ls.get("timeZone"));
        if (this.selectRequest.cnfgRequestTime.indexOf("Tomorrow_") != -1) {
          time = moment.tz(
            moment(
              this.selectRequest.cnfgRequestTime.replace(
                "Tomorrow_",
                tomorrow + " "
              )
            ).format("YYYY-MM-DD HH:mm"),
            Vue.ls.get("timeZone")
          );
        } else {
          time = moment.tz(
            moment(today + " " + this.selectRequest.cnfgRequestTime).format(
              "YYYY-MM-DD HH:mm"
            ),
            Vue.ls.get("timeZone")
          );
        }
        if (time.format("MM/DD/YY") == today) {
          this.tips = this.stringList.tipsContent6;
        } else if (time.format("MM/DD/YY") == tomorrow) {
          this.tips = this.stringList.tipsContent5;
        } else {
          this.tips =
            this.stringList.tipsContent4 + time.format("MM/DD/YY") + ".";
        }
      }else if(this.selectRequest.section == "ScheduleAfterHour"){
         if (requestTime.format("MM/DD/YY") == today) {
           this.tips = this.stringList.tipsContent6;
        } else if (requestTime.format("MM/DD/YY") == tomorrow) {
           this.tips = this.stringList.tipsContent5;
        } else {
          this.tips =
            this.stringList.tipsContent4 + requestTime.format("MM/DD/YY") + ".";
        }
      }
      let time1 = new Date().valueOf().toString();
      let params = {
        hotelId: Vue.ls.get("hotelId"),
        fdaction: this.fdAction,
        createTime: createTime.utc().format(),
        fdhour: Vue.ls.get("overNightHoursInfo"),
        hotelDeptId: this.selectRequest.hotelDeptId,
        inquiryNum: "",
        notes: this.notes,
        requestType: Vue.ls.get("type"),
        roomNumber: this.roomNumber ? this.roomNumber : "None",
        requestTime: requestTime.utc().format(),
        serviceKey: this.meetingRequest,
        serviceType: this.meetingServiceType,
        phone: this.phone,
        createTimeValue: time1,
        tid: encryptedDES(time1.substring(time1.length - 8)),
        language: this.language,
      };
      if (
        Vue.ls.get("overNightHoursInfo") == "Front Desk Hour" ||
        Vue.ls.get("overNightHoursInfo") == "Cycle Front Desk Hour"
      ) {
        params.fdhour = Vue.ls.get("fdOption");
      } else if (
        Vue.ls.get("overNightHoursInfo") == "Cycle Regular Operating Hour"
      ) {
        params.fdhour = "Regular Operating Hour";
      }
      if (this.fdAction == "No") {
        params.status = "Complete-Bot";
      }

      if (!this.submiting) {
        this.submiting = true;
        postAction(this.url.createOrder, params)
          .then((res) => {
            if (res.success) {
              if (this.fdAction == "No") {
                // this.$router.go(-1);
                let query = {};
                query.type = this.$route.query.type;
                query.inno = this.$route.query.inno;
                if (this.$route.query.room) {
                  query.room = this.$route.query.room;
                }
                query.tsp = this.$route.query.tsp;
                this.$router.replace({ name: "Home", query: query });
              } else {
                this.submiting = false;
                if (
                  this.selectRequest.section == "TransferToFD" ||
                  this.selectRequest.section == "TransferToRS" ||
                  this.selectRequest.flow == "15"
                ) {
                  this.thankPop2 = true;
                } else if (this.selectRequest.section == "Answer") {
                  this.thankPop3 = true;
                } else {
                  this.thankPop = true;
                }

                this.okContent = res.msg;
              }
            } else {
              this.submiting = false;
              Toast(res.msg);
            }
          })
          .catch((e) => {
            this.submiting = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.right_keywords_title {
  display: grid !important;
  text-align: right !important;
}
.right_keywords_item {
  text-align: right !important;
}
.right_content {
  /deep/.ant-select-selection__placeholder {
    text-align: right !important;
  }
  /deep/.van-field__control {
    text-align: right !important;
  }
  /deep/.ant-select {
    text-align: right !important;
    direction: rtl;
  }
  /deep/.ant-select-dropdown-menu-item {
    text-align: right !important;
  }
}
.right_text_item {
  text-align: right !important;
}
.header {
  /deep/.van-nav-bar__content {
    height: 50px;
    background-color: #f3f3f3;
    .van-nav-bar__title {
      font-size: 18px;
      // line-height: 20px;
      font-family: $fontFamily;
      color: #4f4f4f;
      font-weight: 400;
    }
    .van-nav-bar__arrow {
      color: #bbbbbb;
      font-size: 22px;
    }
  }
}

.bottom {
  height: 104px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  .btn {
    height: 47px;
    width: 167px;
    border-radius: 20px;
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    span {
      font-size: 16px;
      line-height: 18px;
      font-family: $fontFamily;
      color: #ffffff;
    }
  }
}

.container::-webkit-scrollbar {
  display: none;
}

.container {
  height: calc(100% - 155px);
  background-color: #fff;
  width: 100%;
  padding: 20px 30px;
  overflow-y: auto;

  .form-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    word-break: normal;
    position: relative;
    margin-bottom: $form-title-margin-bottom;

    span {
      color: #333333;
      font-size: 16px;
      line-height: 18px;
      font-family: $fontFamily;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
    }
  }

  .form {
    padding-left: 10px;
    word-break: normal;

    .required {
      position: relative;

      &:before {
        position: absolute;
        content: "*";
        left: -15px;
        width: 15px;
        height: 40px;
        top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .form-item {
      font-size: 15px;
      word-break: normal;
      text-align: left;
      margin-bottom: 20px;
      line-height: 17px;
      font-family: $fontFamily;

      .van-field {
        min-height: 40px;
        border-radius: 6px;
        color: #9a9a9a;
        background-color: #f3f3f3;
        width: 100%;
        padding: 0 15px;
        display: flex;
        align-items: center;
        height: 100%;
        font-family: $fontFamily;
        line-height: 17px;
        font-size: 15px;

        ::-webkit-input-placeholder {
          color: #9a9a9a !important;
        }
      }
      .request-form {
        /deep/.van-field__control {
          height: 56px;
          min-height: 0;
          overflow-y: hidden;
        }
        /deep/.van-field--min-height {
          min-height: 0;
        }
      }

      .comment {
        padding: 0 10px 0 15px;
      }
      .placeholderColor {
        ::-webkit-input-placeholder {
          color: #323233 !important;
        }
      }

      .text {
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        color: #4f4f4f;
      }

      .roomNum {
        width: 100%;
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        color: #323233;
      }

      /deep/.ant-select-selection {
        border-color: transparent;
        background-color: #f3f3f3;
        border-radius: 6px;
        box-shadow: none !important;
      }
      /deep/.ant-select-selection__placeholder {
        color: #9a9a9a;
      }
      /deep/.ant-select-selection:hover {
        border-color: transparent;
        box-shadow: 0 0 0 1px #9a94c0 !important;
      }

      /deep/textarea {
        padding: 10px 0 !important;
      }
    }

    .form-item2 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .van-field {
        width: 215px;
      }

      .van-stepper {
        display: flex;
        flex-flow: nowrap;
      }

      /deep/.van-stepper--round .van-stepper__minus {
        background-color: #ffffff;
        color: #1e4380;
        border: 1px solid #1e4380;
      }

      /deep/.van-stepper--round .van-stepper__plus {
        background-color: #ffffff;
        color: #1e4380;
        border: 1px solid #1e4380;
      }
    }

    .form-item2:last-child {
      margin-bottom: 0px;
    }

    .form-item3 {
      height: 40px;
      text-align: left;
      display: flex;
      align-items: center;

      span {
        line-height: 16px;
        font-size: 14px;
        word-break: normal;
        font-family: $fontFamily;
        color: #1e4380;
      }
    }
  }
}

/deep/.van-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

/deep/.van-nav-bar__title {
  max-width: 80%;
}

// /deep/.van-ellipsis{
//   white-space: pre-wrap;
// }

// .title_mini_font {
//   /deep/ .van-nav-bar__title {
//     font-size: 13px !important;
//   }
// }
.van-popup--bottom.van-popup--round {
  border-radius: 12px 12px 0 0;
}

.van-popup--center.van-popup--round {
  border-radius: 6px;
}

.pop {
  word-break: normal;
  text-align: left;
  max-height: 80vh;

  .pop-title {
    margin: 0 30px;
    height: 60px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 18px;
      line-height: 20px;
      color: #1e4380;
      font-weight: 600;
    }

    .icon {
      font-size: 22px;
      color: #bbbbbb;
      position: absolute;
      left: 0px;
    }

    .shopping {
      font-size: 30px;
      color: #bbbbbb;
      position: absolute;
      right: 0px;
    }

    .van-badge__wrapper {
      display: flex;
    }

    /deep/.van-badge--fixed {
      top: 5px;
      right: 5px;
    }
  }

  .pop-title3 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    // justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-title2 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: $fontFamily;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-container {
    padding: 0 30px;
    max-height: calc(80vh - 160px);
    overflow-y: auto;

    .pop-item {
      // height: $pop-item-height;
      min-height: 50px;
      padding: 10px 0;
      border-top: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 22px;
        margin-right: 25px;
      }
      .text {
        flex: 1;
        color: #000000;
        font-size: 15px;
        line-height: 17px;
        font-family: $fontFamily;
        margin-right: 10px;
      }
      .icon {
        font-size: 18px;
        color: #bbbbbb;
      }

      .select {
        background-color: $primary-color;
        border: 1px solid $primary-color !important;
        color: #ffffff !important;
      }

      .btn {
        height: 25px;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: 1px solid #bbbbbb;
        color: #4f4f4f;

        span {
          font-size: 14px;
          line-height: 16px;
          font-family: $fontFamily;
        }
      }

      &:first-child {
        border-top: 0px;
      }
    }

    .other-select {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 30px;
      padding-left: 50px;

      .select-other {
        width: 100%;
        // margin-left: 50px;
        font-size: 15px;
        font-family: $fontFamily;
      }

      /deep/.ant-select-selection {
        border-color: transparent;
        background-color: #f3f3f3;
        border-radius: 6px;
        box-shadow: none !important;
      }
      /deep/.ant-select-selection__placeholder {
        color: #9a9a9a;
      }
      /deep/.ant-select-selection:hover {
        border-color: transparent;
        box-shadow: 0 0 0 1px #9a94c0 !important;
      }
    }

    .van-stepper {
      display: flex;
      flex-flow: nowrap;
    }

    /deep/.van-stepper--round .van-stepper__minus {
      background-color: #ffffff;
      color: #1e4380;
      border: 1px solid #1e4380;
    }

    /deep/.van-stepper--round .van-stepper__plus {
      background-color: #ffffff;
      color: #1e4380;
      border: 1px solid #1e4380;
    }
  }

  .btn-area {
    height: 100px;
    border-top: 2px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      height: 47px;
      width: 167px;
      border-radius: 20px;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        line-height: 18px;
        font-family: $fontFamily;
        color: #ffffff;
      }
    }
  }

  .pop-info {
    padding: 0 40px;
    .text {
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      font-family: $fontFamily;
      margin-bottom: 11px;
      word-wrap: break-word;
      word-break: normal;
    }

    .info-item {
      padding-left: 20px;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
      font-family: $fontFamily;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #000;
        left: 0px;
        top: 7px;
      }
    }

    .info-item:last-child {
      margin-bottom: 11px;
    }

    /deep/.van-radio__label {
      font-size: 15px;
      line-height: 17px;
      color: #333333;
      font-family: $fontFamily;
    }

    /deep/.van-radio__icon--checked .van-icon {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}

/deep/.ant-select-dropdown {
  z-index: 9999;
  text-align: left;
}
/deep/.ant-select-dropdown-menu-item {
  font-size: 17px;
  color: #000000;
  line-height: 24px;
  font-family: $fontFamily;
}
/deep/.ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #f3f3f3 !important;
}
/deep/.ant-select-dropdown-menu-item-selected {
  background-color: #f3f3f3 !important;
}
/deep/.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #f3f3f3 !important;
}
/deep/ .roomNumber .ant-select-dropdown-menu {
  max-height: 250px !important;
}

/deep/.otherSelect {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/deep/.otherSelect .ant-select-dropdown-menu {
  max-height: 200px !important;
}
/deep/.ant-select-dropdown-menu-item-disabled {
  color: #bbbbbb;
}
/deep/.ant-select-search__field {
  margin-left: -4px;
  padding-left: 2px;
}
/deep/.van-field__body {
  line-height: 20px;
}
</style>
